<template>
  <component :is="isRegistered?'Abogado':'NuevoAbogado'" @ok="cargarRegistro"></component>
</template>
<script>
import {mapState} from 'vuex'
import mRegistro from '@/mixins/registro'
export default {
  components:{
    'Abogado':()=>import('./Abogado'),
    'NuevoAbogado':()=>import('./NuevoAbogado'),
  },
  mixins:[mRegistro],
  computed:{
    ...mapState('abogado',{...'isRegistered'})
  },
  methods:{
    cargarRegistro(){
      this.$router.push('/rpa')
      this.$store.commit("abogado/setIsRegistered",true)
      this.$storage.set("isRegistered",true)
      //this.getDatosRegistro()
    }
  }
}
</script>

